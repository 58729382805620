import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../landing/Header';
import Footer from '../landing/Footer';
import { Heart, Users, Building, LineChart, HandHeart, Check } from 'lucide-react';

const SocialImpactPage = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <Helmet>
        <title>Impact Social - Recouvro</title>
        <meta 
          name="description" 
          content="Découvrez comment Recouvro contribue à réduire la pression financière sur les ménages défavorisés à travers son programme d'aide au logement." 
        />
      </Helmet>

      <Header />

      <main className="py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Hero Section */}
          <div className="text-center mb-16">
            <div className="inline-flex items-center justify-center w-16 h-16 bg-gradient-to-br from-blue-500 to-purple-500 rounded-2xl mb-6">
              <Heart className="w-8 h-8 text-white" />
            </div>
            <h1 className="text-4xl font-bold text-gray-900 mb-6">Notre Impact Social</h1>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              L'une de nos missions est de contribuer à réduire la pression financière qui pèse sur les ménages les moins favorisés.
            </p>
          </div>

          {/* Main Content */}
          <div className="bg-white rounded-xl shadow-sm p-8 mb-12">
            <div className="prose prose-lg max-w-none">
              <h2 className="text-2xl font-semibold mb-6">Notre Programme d'Aide au Logement</h2>
              <p className="mb-8">
                Notre programme d'aide au logement permet de supporter tout ou partie du loyer de certains ménages sélectionnés.
                Cette initiative s'inscrit dans notre vision d'un accès plus équitable au logement.
              </p>

              <div className="bg-blue-50 rounded-xl p-6 mb-8">
                <h3 className="text-xl font-semibold mb-4 flex items-center">
                  <Check className="w-6 h-6 mr-2 text-blue-600" />
                  Participation Automatique
                </h3>
                <p className="mb-4">
                  En choisissant Recouvro, votre agence participe automatiquement à notre programme d'aide au logement. 
                  Vos locataires deviennent automatiquement éligibles à bénéficier du programme selon nos critères de sélection.
                </p>
                <p className="text-sm text-blue-600">
                  La contribution financière au programme est entièrement optionnelle pour les agences.
                </p>
              </div>

              <div className="grid md:grid-cols-2 gap-8 mb-12">
                <div className="bg-gray-50 rounded-xl p-6">
                  <h3 className="text-xl font-semibold mb-4 flex items-center">
                    <Users className="w-6 h-6 mr-2 text-blue-600" />
                    Comment ça marche
                  </h3>
                  <ul className="space-y-3">
                    <li>• Les locataires de Recouvro sont automatiquement éligibles</li>
                    <li>• Évaluation des besoins des ménages</li>
                    <li>• Attribution des aides selon les critères définis</li>
                  </ul>
                </div>

                <div className="bg-purple-50 rounded-xl p-6">
                  <h3 className="text-xl font-semibold mb-4 flex items-center">
                    <HandHeart className="w-6 h-6 mr-2 text-purple-600" />
                    Sources de Financement
                  </h3>
                  <ul className="space-y-3">
                    <li>• Une partie des revenus de Recouvro</li>
                    <li>• Contributions volontaires des agences</li>
                    <li>• Partenariats avec des ONGs</li>
                    <li>• Soutien d'entreprises mécènes</li>
                  </ul>
                </div>
              </div>

              <h2 className="text-2xl font-semibold mb-6">Impact pour les Agences Immobilières</h2>
              <div className="grid md:grid-cols-3 gap-8 mb-12">
                <div className="bg-gray-50 rounded-xl p-6">
                  <Building className="w-6 h-6 text-gray-600 mb-4" />
                  <h3 className="text-lg font-semibold mb-2">Image Renforcée</h3>
                  <p>Valorisez votre engagement social auprès de vos clients</p>
                </div>
                
                <div className="bg-gray-50 rounded-xl p-6">
                  <LineChart className="w-6 h-6 text-gray-600 mb-4" />
                  <h3 className="text-lg font-semibold mb-2">Paiements Garantis</h3>
                  <p>Sécurisez vos revenus grâce au programme de support</p>
                </div>

                <div className="bg-gray-50 rounded-xl p-6">
                  <Users className="w-6 h-6 text-gray-600 mb-4" />
                  <h3 className="text-lg font-semibold mb-2">Relations Améliorées</h3>
                  <p>Développez des relations positives avec vos locataires</p>
                </div>
              </div>

              <h2 className="text-2xl font-semibold mb-6">Contribuer Davantage</h2>
              <p className="mb-8">
                Si vous souhaitez aller plus loin dans votre engagement, vous pouvez choisir de contribuer financièrement au programme. 
                Cette contribution est entièrement volontaire et permet d'augmenter l'impact du programme.
              </p>
              
              <div className="text-center">
                <a 
                  href="#contact" 
                  className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gradient-to-r from-blue-600 to-purple-600 hover:opacity-90 transition-opacity"
                >
                  En Savoir Plus sur la Contribution Volontaire
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default SocialImpactPage;