import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertCircle } from 'lucide-react';
import api from '../../../api';

const CreateTenant = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    schema_name: '',
    paid_until: '',
    on_trial: false,
    trial_ends: '',
    country: '',
    tenant_type: 'agency',
    tenant_mail: '',
    properties_authorized: 5,
    users_authorized: 5
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      await api.post('/tenants/', formData);
      navigate('/admin/tenants');
    } catch (error) {
      setError(
        error.response?.data?.schema_name || 
        error.response?.data?.detail || 
        error.response?.data?.error || 
        'Une erreur est survenue lors de la création de l\'agence'
      );
      setLoading(false);
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-6">
      <h2 className="text-2xl font-bold mb-6">Créer une Nouvelle Agence</h2>
      
      {error && (
        <div className="mb-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded-md flex items-center">
          <AlertCircle className="h-5 w-5 mr-2 flex-shrink-0" />
          <span>{error}</span>
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700">Nom de l'agence</label>
          <input
            type="text"
            required
            maxLength={100}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            value={formData.name}
            onChange={(e) => setFormData({...formData, name: e.target.value})}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Email de l'agence</label>
          <input
            type="email"
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            value={formData.tenant_mail}
            onChange={(e) => setFormData({...formData, tenant_mail: e.target.value})}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Identifiant unique</label>
          <input
            type="text"
            required
            pattern="[a-z0-9-]+"
            maxLength={100}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            value={formData.schema_name}
            onChange={(e) => setFormData({...formData, schema_name: e.target.value.toLowerCase()})}
          />
          <p className="mt-1 text-sm text-gray-500">
            Sera utilisé pour créer le domaine: {formData.schema_name}.recouvro.com
            <br />
            Utilisez uniquement des lettres minuscules, des chiffres et des tirets (-)
          </p>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Pays</label>
          <input
            type="text"
            maxLength={100}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            value={formData.country}
            onChange={(e) => setFormData({...formData, country: e.target.value})}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Type</label>
          <select
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            value={formData.tenant_type}
            onChange={(e) => setFormData({...formData, tenant_type: e.target.value})}
          >
            <option value="agency">Agence immobilière</option>
            <option value="individual">Propriétaire individuel</option>
          </select>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Nombre d'utilisateurs autorisés</label>
            <input
              type="number"
              required
              min="1"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              value={formData.users_authorized}
              onChange={(e) => setFormData({...formData, users_authorized: parseInt(e.target.value)})}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Nombre de propriétés autorisées</label>
            <input
              type="number"
              required
              min="1"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              value={formData.properties_authorized}
              onChange={(e) => setFormData({...formData, properties_authorized: parseInt(e.target.value)})}
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Payé jusqu'au</label>
          <input
            type="date"
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            value={formData.paid_until}
            onChange={(e) => setFormData({...formData, paid_until: e.target.value})}
          />
        </div>

        <div className="flex items-center space-x-4">
          <div className="flex items-center">
            <input
              type="checkbox"
              id="on_trial"
              className="h-4 w-4 text-blue-600 rounded"
              checked={formData.on_trial}
              onChange={(e) => setFormData({...formData, on_trial: e.target.checked})}
            />
            <label htmlFor="on_trial" className="ml-2 block text-sm text-gray-700">
              En période d'essai
            </label>
          </div>
          
          {formData.on_trial && (
            <div>
              <label className="block text-sm font-medium text-gray-700">Fin de la période d'essai</label>
              <input
                type="date"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                value={formData.trial_ends || ''}
                onChange={(e) => setFormData({...formData, trial_ends: e.target.value})}
              />
            </div>
          )}
        </div>

        <div className="flex justify-end space-x-4">
          <button
            type="button"
            onClick={() => navigate('/admin/tenants')}
            className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
          >
            Annuler
          </button>
          <button
            type="submit"
            disabled={loading}
            className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 disabled:opacity-50"
          >
            {loading ? 'Création...' : 'Créer l\'agence'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateTenant;