import React from 'react';
import Footer from '../landing/Footer';
import Header from '../landing/Header';
import { Helmet } from 'react-helmet';

function LegalMentionsPage() {
    return (
        <div className="min-h-screen flex flex-col bg-gray-50">
            <Helmet>
                <title>Mentions Légales - Recouvro</title>
                <meta 
                    name="description" 
                    content="Mentions légales de Recouvro - Informations légales et conditions d'utilisation" 
                />
            </Helmet>

            <Header />

            <main className="flex-grow py-16">
                <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="bg-white rounded-xl shadow-sm p-8 md:p-12">
                        <h1 className="text-3xl font-bold text-gray-900 mb-8 text-center">
                            Mentions Légales
                        </h1>
                        
                        <div className="space-y-6 text-gray-600">
                            <section>
                                <h2 className="text-xl font-semibold text-gray-900 mb-3">Éditeur du Site</h2>
                                <p>Le site Recouvro est édité par DIGHITEC INNOVATIONS, une Société à Responsabilité Limitée (SARL) au capital social de 100 000 FCFA, immatriculée au REGISTRE DU COMMERCE ET DU CREDIT MOBILIER sous le numéro RB/ABC/23 B, et domiciliée à Abomey-Calavi.</p>
                                <p className="mt-2">IFU : 3202322510966</p>
                            </section>

                            <section>
                                <h2 className="text-xl font-semibold text-gray-900 mb-3">Hébergement</h2>
                                <p>Le site Recouvro est hébergé par DIGITAL OCEAN.</p>
                            </section>

                            <section>
                                <h2 className="text-xl font-semibold text-gray-900 mb-3">Contact</h2>
                                <p>Pour toute question, vous pouvez contacter l'éditeur à l'adresse suivante : Joy@recouvro.com.</p>
                            </section>

                            <section>
                                <h2 className="text-xl font-semibold text-gray-900 mb-3">Propriété Intellectuelle</h2>
                                <p>Tous les contenus présents sur le site Recouvro, incluant, de façon non limitative, les graphismes, images, textes, vidéos, animations, sons, logos, gifs et icônes ainsi que leur mise en forme sont la propriété exclusive de DIGHITEC INNOVATIONS, à l'exception des marques, logos ou contenus appartenant à d'autres sociétés partenaires ou auteurs.</p>
                            </section>

                            <section>
                                <h2 className="text-xl font-semibold text-gray-900 mb-3">Conditions Générales d'Utilisation</h2>
                                <p>L'accès au site Recouvro implique l'acceptation pleine et entière des conditions générales d'utilisation décrites dans les présentes mentions légales. Ces conditions sont susceptibles d'être modifiées ou complétées à tout moment, les utilisateurs du site sont donc invités à les consulter de manière régulière.</p>
                            </section>

                            <section>
                                <h2 className="text-xl font-semibold text-gray-900 mb-3">Protection des Données Personnelles</h2>
                                <p>DIGHITEC INNOVATIONS s'engage à respecter la confidentialité des données personnelles collectées sur le site Recouvro, conformément à la législation en vigueur. Pour toute information relative à la collecte et au traitement des données personnelles, veuillez consulter notre <a href="/legal-mentions" className="text-blue-600 hover:text-blue-800 underline">Politique de Confidentialité</a>.</p>
                            </section>
                        </div>
                    </div>
                </div>
            </main>

            <Footer />
        </div>
    );
}

export default LegalMentionsPage;