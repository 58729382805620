import React from 'react';
import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import { LayoutDashboard, Building2, Banknote, LogOut } from 'lucide-react';

const AdminLayout = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = async () => {
    await logout();
    navigate('/admin/login');
  };

  const isActive = (path) => {
    return location.pathname.startsWith(path) ? 'bg-blue-700' : '';
  };

  return (
    <div className="min-h-screen bg-gray-100 flex">
      {/* Sidebar */}
      <div className="w-64 bg-blue-600 text-white">
        <div className="p-4">
          <Link to="/admin/dashboard" className="text-xl font-bold">
            Recouvro Admin
          </Link>
        </div>
        <nav className="mt-4">
          <Link
            to="/admin/dashboard"
            className={`flex items-center px-4 py-3 hover:bg-blue-700 ${isActive('/admin/dashboard')}`}
          >
            <LayoutDashboard className="w-5 h-5 mr-3" />
            Tableau de bord
          </Link>
          <Link
            to="/admin/tenants"
            className={`flex items-center px-4 py-3 hover:bg-blue-700 ${isActive('/admin/tenants')}`}
          >
            <Building2 className="w-5 h-5 mr-3" />
            Agences
          </Link>
          <Link
            to="/admin/withdrawals"
            className={`flex items-center px-4 py-3 hover:bg-blue-700 ${isActive('/admin/withdrawals')}`}
          >
            <Banknote className="w-5 h-5 mr-3" />
            Retraits
          </Link>
        </nav>
        <div className="absolute bottom-0 w-64 p-4">
          <button
            onClick={handleLogout}
            className="flex items-center w-full px-4 py-2 text-white hover:bg-blue-700 rounded"
          >
            <LogOut className="w-5 h-5 mr-3" />
            Déconnexion
          </button>
        </div>
      </div>

      {/* Main content */}
      <div className="flex-1">
        <main className="p-6">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default AdminLayout;