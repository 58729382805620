import axios from 'axios';

// Base URL for the API
const baseURL = `https://recouvro.com/`;


const API_VERSION = '/api/v1';

const api = axios.create({
  baseURL,
  withCredentials: true,
});

// List of public routes that don't need token refresh
const publicRoutes = [
  `${API_VERSION}/login/`,
  `${API_VERSION}/api/token/refresh/`,
  `${API_VERSION}/logout/`
];


let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve();
    }
  });
  failedQueue = [];
};

// In the interceptor, update the check to use the full URL path
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    
    // Get the full URL path without the base URL
    const requestPath = originalRequest.url;
    
    // Debug log to see what paths we're dealing with
    console.log('Request path:', requestPath);
    console.log('Is public route?', publicRoutes.includes(requestPath));

    // Don't attempt refresh for public routes or non-401 errors
    if (
      publicRoutes.includes(requestPath) || 
      error.response?.status !== 401 || 
      originalRequest._retry ||
      requestPath.includes('login') // Additional safety check for login endpoints
    ) {
      return Promise.reject(error);
    }

    // If request is already retrying, add to queue
    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      })
        .then(() => {
          return api(originalRequest);
        })
        .catch(err => {
          return Promise.reject(err);
        });
    }

    originalRequest._retry = true;
    isRefreshing = true;

    try {
      await api.post('/api/v1/api/token/refresh/');
      processQueue(null);
      return api(originalRequest);
      
    } catch (refreshError) {
      processQueue(refreshError, null);
      
      // Only redirect to login if we're not already on a public route
      const currentPath = window.location.pathname;
      if (!publicRoutes.some(route => currentPath.includes(route.replace(/\//g, '')))) {
        window.location.href = '/admin/login';
      }
      
      return Promise.reject(refreshError);
    } finally {
      isRefreshing = false;
    }
  }
);

// API wrapper functions for common operations
const apiService = {
  login: async (credentials) => {
    try {
      const response = await api.post('/api/v1/login/', credentials);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  logout: async () => {
    try {
      await api.post('/api/v1/logout/');
    } catch (error) {
      console.error('Logout error:', error);
    }
  },

  get: async (url, config = {}) => {
    try {
      const response = await api.get(url, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  post: async (url, data, config = {}) => {
    try {
      const response = await api.post(url, data, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  put: async (url, data, config = {}) => {
    try {
      const response = await api.put(url, data, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  patch: async (url, data, config = {}) => {
    try {
      const response = await api.patch(url, data, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  delete: async (url, config = {}) => {
    try {
      const response = await api.delete(url, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
};

export default apiService;