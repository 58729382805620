import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from '../landing/Header';
import Footer from '../landing/Footer';
import api from '../../api';

function RegistrationPage() {
    const [formData, setFormData] = useState({
        user_name: '',
        agency_name: '',
        number_of_account_desired: '',
        email: '',
        heard_of_from: '',
        phone_number: '',
        registran_role: '',
        agency_or_individual: '',
        message: ''
    });
    const [modalVisible, setModalVisible] = useState(false);
    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await api.post('/waitinglist/', formData);
            setModalVisible(true);
            setFormData({
                user_name: '',
                agency_name: '',
                number_of_account_desired: '',
                email: '',
                heard_of_from: '',
                phone_number: '',
                registran_role: '',
                agency_or_individual: '',
                message: ''
            });
        } catch (error) {
            console.error("There was an error submitting the form!", error);
        }
    };

    return (
        <div className="min-h-screen flex flex-col bg-gray-50">
            <Helmet>
                <title>Inscription - Recouvro</title>
                <meta 
                    name="description" 
                    content="Inscrivez-vous à Recouvro pour simplifier la gestion de vos locations" 
                />
            </Helmet>

            <Header />

            <main className="flex-grow py-16">
                <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="bg-white rounded-xl shadow-sm p-8">
                        <h1 className="text-3xl font-bold text-gray-900 mb-8 text-center">
                            Inscription
                        </h1>

                        <form onSubmit={handleSubmit} className="space-y-6">
                            <div>
                                <label htmlFor="user_name" className="block text-sm font-medium text-gray-700">
                                    Votre nom
                                </label>
                                <input
                                    type="text"
                                    id="user_name"
                                    name="user_name"
                                    value={formData.user_name}
                                    onChange={handleChange}
                                    maxLength={30}
                                    required
                                    className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-blue-600 focus:ring-blue-500 text-gray-900"
                                />
                            </div>

                            <div>
                                <label htmlFor="agency_or_individual" className="block text-sm font-medium text-gray-700">
                                    Agence ou Propriétaire Individuel
                                </label>
                                <select
                                    id="agency_or_individual"
                                    name="agency_or_individual"
                                    value={formData.agency_or_individual}
                                    onChange={handleChange}
                                    required
                                    className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-blue-600 focus:ring-blue-500 text-gray-900"
                                >
                                    <option value="">Sélectionner</option>
                                    <option value="Agence">Agence</option>
                                    <option value="Individuel">Individuel</option>
                                </select>
                            </div>

                            <div>
                                <label htmlFor="agency_name" className="block text-sm font-medium text-gray-700">
                                    Nom de l'agence
                                </label>
                                <input
                                    type="text"
                                    id="agency_name"
                                    name="agency_name"
                                    value={formData.agency_name}
                                    onChange={handleChange}
                                    maxLength={30}
                                    className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-blue-600 focus:ring-blue-500 text-gray-900"
                                />
                            </div>

                            <div>
                                <label htmlFor="registran_role" className="block text-sm font-medium text-gray-700">
                                    Votre Poste
                                </label>
                                <input
                                    type="text"
                                    id="registran_role"
                                    name="registran_role"
                                    value={formData.registran_role}
                                    onChange={handleChange}
                                    maxLength={30}
                                    placeholder="exemple : Gérant agence, Agent immo"
                                    required
                                    className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-blue-600 focus:ring-blue-500 text-gray-900"
                                />
                            </div>

                            <div>
                                <label htmlFor="number_of_account_desired" className="block text-sm font-medium text-gray-700">
                                    Nombre de comptes souhaités
                                </label>
                                <input
                                    type="number"
                                    id="number_of_account_desired"
                                    name="number_of_account_desired"
                                    value={formData.number_of_account_desired}
                                    onChange={handleChange}
                                    required
                                    className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-blue-600 focus:ring-blue-500 text-gray-900"
                                />
                            </div>

                            <div>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                    className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-blue-600 focus:ring-blue-500 text-gray-900"
                                />
                            </div>

                            <div>
                                <label htmlFor="phone_number" className="block text-sm font-medium text-gray-700">
                                    Numéro de téléphone
                                </label>
                                <input
                                    type="tel"
                                    id="phone_number"
                                    name="phone_number"
                                    value={formData.phone_number}
                                    onChange={handleChange}
                                    required
                                    className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-blue-600 focus:ring-blue-500 text-gray-900"
                                />
                            </div>

                            <div>
                                <label htmlFor="heard_of_from" className="block text-sm font-medium text-gray-700">
                                    Comment avez-vous entendu parler de nous?
                                </label>
                                <select
                                    id="heard_of_from"
                                    name="heard_of_from"
                                    value={formData.heard_of_from}
                                    onChange={handleChange}
                                    required
                                    className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-blue-600 focus:ring-blue-500 text-gray-900"
                                >
                                    <option value="">Sélectionner</option>
                                    <option value="Internet">Internet</option>
                                    <option value="Téléphone">Téléphone</option>
                                    <option value="Email">Email</option>
                                    <option value="Facebook">Facebook</option>
                                    <option value="Instagram">Instagram</option>
                                    <option value="LinkedIn">LinkedIn</option>
                                    <option value="X">X</option>
                                    <option value="Bouche à oreille">Bouche à oreille</option>
                                    <option value="Publicité">Publicité</option>
                                    <option value="Invitation Spéciale">Invitation Spéciale</option>
                                    <option value="Autre">Autre</option>
                                </select>
                            </div>

                            <div>
                                <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                                    Message (optionnel)
                                </label>
                                <textarea
                                    id="message"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    maxLength={300}
                                    rows={4}
                                    className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-blue-600 focus:ring-blue-500 text-gray-900"
                                />
                            </div>

                            <button
                                type="submit"
                                className="w-full py-3 px-4 rounded-lg font-medium bg-gradient-to-r from-blue-600 via-purple-500 to-pink-500 text-white hover:opacity-90 transition-opacity"
                            >
                                Soumettre
                            </button>
                        </form>
                    </div>
                </div>
            </main>

            {modalVisible && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
                    <div className="bg-white rounded-xl p-6 max-w-sm w-full">
                        <p className="text-gray-900 mb-4 text-center">
                            Merci pour votre inscription ! Nous vous contacterons dans les 48 heures.
                        </p>
                        <button 
                            onClick={() => {
                                navigate('/');
                                setModalVisible(false);
                            }}
                            className="w-full py-2 px-4 rounded-lg font-medium bg-gradient-to-r from-blue-600 to-purple-600 text-white hover:opacity-90 transition-opacity"
                        >
                            Retour à la page d'accueil
                        </button>
                    </div>
                </div>
            )}

            <Footer />
        </div>
    );
}

export default RegistrationPage;