import React from 'react';

const Hero = () => {
  const youtubeVideoId = "Bxr31idYUdo?si=_R8MipcxqUHHsQbg"; // Replace with your actual video ID

  return (
    <div className="relative overflow-hidden bg-gradient-to-b from-blue-50 via-purple-50 to-white py-16">
      <div className="absolute inset-0 bg-grid-slate-100 [mask-image:linear-gradient(0deg,white,rgba(255,255,255,0.6))] bg-top"></div>
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h1 className="text-4xl md:text-6xl font-bold text-gray-900 mb-6">
            Recouvrez Simplement
          </h1>
          <p className="text-xl text-gray-600 mb-8 max-w-3xl mx-auto">
            Nous aidons les agences immobilières à collecter les loyers sans stress, réduire les impayés et économiser de l'argent.
          </p>
        </div>

        {/* Video Section */}
        <div className="max-w-6xl mx-auto rounded-xl overflow-hidden shadow-xl w-[90%]">
          <div className="relative pt-[56.25%]">
            <iframe
              src={`https://www.youtube.com/embed/${youtubeVideoId}`}
              title="Recouvro Présentation"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="absolute top-0 left-0 w-full h-full"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;