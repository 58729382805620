import React, { useState, useEffect } from 'react';
import api from '../../api';
import { Users, Clock, Building } from 'lucide-react';

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stats, setStats] = useState({
    totalAgencies: 0,
    activeAgencies: 0,
    pendingWithdrawals: 0
  });

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const fetchDashboardData = async () => {
    setLoading(true);
    try {
      // Fetch tenants
      const tenantResponse = await api.get('/tenants/');
      const tenants = Array.isArray(tenantResponse) ? tenantResponse : [];
      
      // Filter out public tenant and calculate active agencies
      const actualTenants = tenants.filter(tenant => tenant.schema_name !== 'public');
      const activeAgencies = actualTenants.filter(tenant => 
        tenant.paid_until && new Date(tenant.paid_until) > new Date()
      );

      // Initialize pending withdrawals count
      let totalPendingWithdrawals = 0;

      // Fetch pending withdrawals for each tenant
      for (const tenant of actualTenants) {
        try {
          const withdrawalResponse = await api.get('/management/manage-withdrawals/', {
            params: { 
              schema_name: tenant.schema_name,
              status: 'PENDING' // Only fetch pending withdrawals
            }
          });
          
          // Add the count of pending withdrawals from the new response structure
          if (withdrawalResponse?.withdrawals?.pending_count) {
            totalPendingWithdrawals += withdrawalResponse.withdrawals.pending_count;
          }
        } catch (error) {
          console.error(`Error fetching withdrawals for ${tenant.schema_name}:`, error);
        }
      }

      setStats({
        totalAgencies: actualTenants.length,
        activeAgencies: activeAgencies.length,
        pendingWithdrawals: totalPendingWithdrawals
      });
      setError(null);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      setError('Erreur lors du chargement des données');
    } finally {
      setLoading(false);
    }
  };

  if (error) {
    return (
      <div className="p-6 text-center text-red-600">
        <p>{error}</p>
        <button 
          onClick={fetchDashboardData}
          className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
        >
          Réessayer
        </button>
      </div>
    );
  }

  return (
    <div className="p-6">
      <h2 className="text-2xl font-bold mb-6">Tableau de bord</h2>
      
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="bg-blue-100 p-6 rounded-lg shadow hover:shadow-md transition-shadow">
            <div className="flex items-center justify-between">
              <div>
                <h3 className="text-lg font-semibold mb-2">Total Agences</h3>
                <p className="text-3xl font-bold text-blue-600">{stats.totalAgencies}</p>
              </div>
              <Building className="h-12 w-12 text-blue-500 opacity-50" />
            </div>
          </div>

          <div className="bg-green-100 p-6 rounded-lg shadow hover:shadow-md transition-shadow">
            <div className="flex items-center justify-between">
              <div>
                <h3 className="text-lg font-semibold mb-2">Retraits en attente</h3>
                <p className="text-3xl font-bold text-green-600">{stats.pendingWithdrawals}</p>
              </div>
              <Clock className="h-12 w-12 text-green-500 opacity-50" />
            </div>
          </div>

          <div className="bg-purple-100 p-6 rounded-lg shadow hover:shadow-md transition-shadow">
            <div className="flex items-center justify-between">
              <div>
                <h3 className="text-lg font-semibold mb-2">Agences actives</h3>
                <p className="text-3xl font-bold text-purple-600">{stats.activeAgencies}</p>
              </div>
              <Users className="h-12 w-12 text-purple-500 opacity-50" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;