import React from 'react';

const Testimonials = () => {
  const testimonials = [
    {
      name: "Jonathan",
      role: "Gérant d'agence immobilière",
      content: "Recouvro a transformé notre façon de gérer les loyers. L'application est facile à utiliser et nos locataires apprécient la simplicité de paiement.",
      company: ""
    },
    {
      name: "Gigonou",
      role: "Directeur Commercial",
      content: "Avec Recouvro, nous avons réduit le temps passé sur la gestion des quittances. C'est une véritable révolution pour notre agence.",
      company: ""
    },
    {
      name: "Sarah",
      role: "Responsable Location",
      content: "Le suivi des paiements en temps réel nous permet d'être plus efficaces. Nos propriétaires sont ravis de la transparence.",
      company: ""
    }
  ];

  return (
    <div id="testimonials" className="bg-gradient-to-b from-white to-blue-50 py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">
            Ce que disent nos clients
          </h2>
          <p className="text-xl text-gray-600">
            Découvrez les retours d'expérience de nos utilisateurs
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          {testimonials.map((testimonial, index) => (
            <div 
              key={index} 
              className="bg-white rounded-xl p-8 shadow-md hover:shadow-lg transition-shadow border border-gray-100"
            >
              <div className="mb-6">
                <p className="text-gray-600 italic">"{testimonial.content}"</p>
              </div>
              <div className="flex items-center">
                <div className="w-12 h-12 bg-gradient-to-r from-blue-600 to-purple-600 rounded-full flex items-center justify-center text-white font-bold text-lg">
                  {testimonial.name[0]}
                </div>
                <div className="ml-4">
                  <p className="font-semibold text-gray-900">{testimonial.name}</p>
                  <p className="text-gray-600 text-sm">{testimonial.role}</p>
                  <p className="text-blue-600 text-sm">{testimonial.company}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Testimonials;