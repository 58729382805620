import React, { useState, useEffect } from 'react';
import api from '../../../api';
import { AlertCircle, Plus } from 'lucide-react';

const WITHDRAWAL_STATUSES = {
  'PENDING': { label: 'En attente', class: 'text-yellow-700 bg-yellow-100' },
  'SUCCESSFUL': { label: 'Effectué', class: 'text-green-700 bg-green-100' },
  'FAILED': { label: 'Échoué', class: 'text-red-700 bg-red-100' }
};

const WITHDRAWAL_METHODS = [
  { value: 'mobile_money', label: 'Mobile Money' },
  { value: 'bank', label: 'Virement Bancaire' }
];

const WithdrawalList = () => {
  const [withdrawals, setWithdrawals] = useState({ tenant: '', withdrawals: { all: [], grouped: {} } });
  const [loading, setLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [selectedTenant, setSelectedTenant] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [tenants, setTenants] = useState([]);
  const [error, setError] = useState('');
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [statusUpdate, setStatusUpdate] = useState(null);
  const [newWithdrawal, setNewWithdrawal] = useState({
    amount_to_withdraw: '',
    withdrawal_method: ''
  });
  
  useEffect(() => {
    fetchTenants();
  }, []);

  useEffect(() => {
    if (selectedTenant) {
      fetchWithdrawals(selectedTenant);
    }
  }, [selectedTenant]);

  const fetchTenants = async () => {
    setLoading(true);
    try {
      const response = await api.get('/tenants/');
      const filteredTenants = Array.isArray(response) 
        ? response.filter(tenant => tenant.schema_name !== 'public')
        : [];

      setTenants(filteredTenants);
      if (filteredTenants.length > 0) {
        setSelectedTenant(filteredTenants[0].schema_name);
      }
    } catch (error) {
      console.error('Error fetching tenants:', error);
      setError('Erreur lors du chargement des agences');
    } finally {
      setLoading(false);
    }
  };

  const fetchWithdrawals = async (schemaName) => {
    setLoading(true);
    try {
      const response = await api.get('/management/manage-withdrawals/', {
        params: { 
          schema_name: schemaName,
          status: selectedStatus !== 'all' ? selectedStatus : undefined
        }
      });
      setWithdrawals(response || { tenant: schemaName, withdrawals: { all: [], grouped: {} } });
      setError('');
    } catch (error) {
      console.error('Error fetching withdrawals:', error);
      setWithdrawals({ tenant: schemaName, withdrawals: { all: [], grouped: {} } });
      setError('Erreur lors du chargement des retraits');
    } finally {
      setLoading(false);
    }
  };

  const handleStatusUpdate = async () => {
    if (!statusUpdate) return;
    
    setActionLoading(true);
    try {
      await api.patch('/management/manage-withdrawals/', {
        schema_name: selectedTenant,
        withdrawal_uuid: statusUpdate.uuid,
        new_status: statusUpdate.status
      });
      await fetchWithdrawals(selectedTenant);
      setError('');
    } catch (error) {
      console.error('Error updating withdrawal status:', error);
      setError('Erreur lors de la mise à jour du statut');
    } finally {
      setActionLoading(false);
      setShowConfirmModal(false);
      setStatusUpdate(null);
    }
  };

  const handleCreateWithdrawal = async (e) => {
    e.preventDefault();
    setActionLoading(true);
    try {
      await api.post('/management/manage-withdrawals/', {
        schema_name: selectedTenant,
        ...newWithdrawal
      });
      setShowCreateModal(false);
      setNewWithdrawal({ amount_to_withdraw: '', withdrawal_method: '' });
      await fetchWithdrawals(selectedTenant);
      setError('');
    } catch (error) {
      console.error('Error creating withdrawal:', error);
      setError('Erreur lors de la création du retrait');
    } finally {
      setActionLoading(false);
    }
  };

  const displayedWithdrawals = withdrawals.withdrawals?.all || [];

  if (loading && tenants.length === 0) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (!loading && tenants.length === 0) {
    return (
      <div className="p-6 text-center">
        <AlertCircle className="mx-auto h-12 w-12 text-yellow-500 mb-4" />
        <h3 className="text-lg font-medium text-gray-900 mb-2">Aucune agence disponible</h3>
        <p className="text-gray-500">Veuillez d'abord créer une agence.</p>
      </div>
    );
  }

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold">Gestion des Retraits</h2>
        <button
          onClick={() => setShowCreateModal(true)}
          className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 flex items-center"
        >
          <Plus className="h-5 w-5 mr-2" />
          Nouveau retrait
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Agence
          </label>
          <select
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
            value={selectedTenant}
            onChange={(e) => setSelectedTenant(e.target.value)}
            disabled={loading || actionLoading}
          >
            {tenants.map((tenant) => (
              <option key={tenant.schema_name} value={tenant.schema_name}>
                {tenant.name} ({tenant.schema_name})
              </option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Statut
          </label>
          <select
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
            value={selectedStatus}
            onChange={(e) => {
              setSelectedStatus(e.target.value);
              fetchWithdrawals(selectedTenant);
            }}
            disabled={loading || actionLoading}
          >
            <option value="all">Tous les statuts</option>
            {Object.entries(WITHDRAWAL_STATUSES).map(([value, { label }]) => (
              <option key={value} value={value}>{label}</option>
            ))}
          </select>
        </div>
      </div>

      {error && (
        <div className="mb-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded-md flex items-center">
          <AlertCircle className="h-5 w-5 mr-2" />
          <span>{error}</span>
          <button 
            onClick={() => {
              setError('');
              fetchWithdrawals(selectedTenant);
            }}
            className="ml-auto text-sm underline hover:no-underline"
          >
            Réessayer
          </button>
        </div>
      )}

      {loading ? (
        <div className="flex justify-center items-center py-8">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
        </div>
      ) : (
        <div className="bg-white shadow overflow-hidden sm:rounded-md">
          <ul className="divide-y divide-gray-200">
            {displayedWithdrawals.map((withdrawal) => (
              <li key={withdrawal.withdrawal_uuid} className="px-6 py-4">
                <div className="flex items-center justify-between flex-wrap gap-4">
                  <div>
                    <h3 className="text-lg font-medium text-gray-900">
                      Montant: {withdrawal.amount_to_withdraw.toLocaleString('fr-FR')} FCFA
                    </h3>
                    <p className="text-sm text-gray-500">
                      Date: {new Date(withdrawal.withdrawal_date).toLocaleDateString('fr-FR')}
                    </p>
                    <p className="text-sm text-gray-500">
                      ID unique: {withdrawal.withdrawal_uuid}
                    </p>
                    <p className="text-sm text-gray-500">
                      Méthode: {withdrawal.withdrawal_method === 'mobile_money' ? 'Mobile Money' : 'Virement Bancaire'}
                    </p>
                  </div>
                  <div className="flex items-center space-x-4">
                    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${WITHDRAWAL_STATUSES[withdrawal.withdrawal_status].class}`}>
                      {WITHDRAWAL_STATUSES[withdrawal.withdrawal_status].label}
                    </span>
                    <select
                      className="block w-48 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                      value={withdrawal.withdrawal_status}
                      onChange={(e) => {
                        setStatusUpdate({
                          uuid: withdrawal.withdrawal_uuid,
                          status: e.target.value,
                          currentStatus: withdrawal.withdrawal_status
                        });
                        setShowConfirmModal(true);
                      }}
                      disabled={actionLoading}
                    >
                      {Object.entries(WITHDRAWAL_STATUSES).map(([value, { label }]) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </li>
            ))}
            {displayedWithdrawals.length === 0 && (
              <li className="px-6 py-4 text-center text-gray-500">
                Aucun retrait trouvé
              </li>
            )}
          </ul>
        </div>
      )}

      {/* Create Withdrawal Modal */}
      {showCreateModal && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Nouveau retrait</h3>
            <form onSubmit={handleCreateWithdrawal}>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Montant
                  </label>
                  <input
                    type="number"
                    required
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md"
                    value={newWithdrawal.amount_to_withdraw}
                    onChange={(e) => setNewWithdrawal(prev => ({
                      ...prev,
                      amount_to_withdraw: e.target.value
                    }))}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Méthode de retrait
                  </label>
                  <select
                    required
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md"
                    value={newWithdrawal.withdrawal_method}
                    onChange={(e) => setNewWithdrawal(prev => ({
                      ...prev,
                      withdrawal_method: e.target.value
                    }))}
                  >
                    <option value="">Sélectionner une méthode</option>
                    {WITHDRAWAL_METHODS.map(method => (
                      <option key={method.value} value={method.value}>
                        {method.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="mt-6 flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => setShowCreateModal(false)}
                  className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                  Annuler
                </button>
                <button
                  type="submit"
                  disabled={actionLoading}
                  className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:opacity-50"
                >
                  {actionLoading ? 'Création...' : 'Créer'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Status Update Confirmation Modal */}
      {showConfirmModal && statusUpdate && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Confirmer le changement de statut</h3>
            <p className="text-sm text-gray-500 mb-4">
              Êtes-vous sûr de vouloir changer le statut de{' '}
              <span className="font-medium">{WITHDRAWAL_STATUSES[statusUpdate.currentStatus].label}</span> à{' '}
              <span className="font-medium">{WITHDRAWAL_STATUSES[statusUpdate.status].label}</span> ?
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => {
                  setShowConfirmModal(false);
                  setStatusUpdate(null);
                }}
                className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Annuler
              </button>
              <button
                onClick={handleStatusUpdate}
                disabled={actionLoading}
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:opacity-50"
              >
                {actionLoading ? 'Modification...' : 'Confirmer'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WithdrawalList;