import React from 'react';
import { Building2, Receipt, BarChart, Users } from 'lucide-react';

const Features = () => {
  const features = [
    {
      title: "Gestion Complète",
      description: "Gérez facilement vos biens et locataires dans une interface unique",
      icon: Building2
    },
    {
      title: "Paiements en Ligne",
      description: "Collectez les loyers via mobile money avec facturation automatiques",
      icon: Receipt
    },
    {
      title: "Suivi en Temps Réel",
      description: "Tableau de bord pour suivre tous vos encaissements",
      icon: BarChart
    },
    {
      title: "Multi-collaborateurs",
      description: "Travaillez en équipe avec gestion des accès",
      icon: Users
    }
  ];

  return (
    <div id="features" className="bg-gray-50 py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">
            Tout ce dont vous avez besoin
          </h2>
          <p className="text-xl text-gray-600">
            Une solution complète pour la gestion de vos locations
          </p>
        </div>
        
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <div key={index} className="group bg-white rounded-xl p-6 border border-transparent bg-gradient-to-br from-white to-blue-50 hover:border-blue-200 transition-all hover:shadow-md">
              <div className="bg-gradient-to-r from-blue-600 to-purple-600 w-12 h-12 rounded-lg flex items-center justify-center mb-4">
                <feature.icon className="w-6 h-6 text-white" />
              </div>
              <h3 className="text-lg font-semibold mb-2">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Features;