import React, { useState, useEffect } from 'react';
import { Check, ShieldCheck, Clock } from 'lucide-react';
import api from '../../api';

// Registration Modal Component
const RegistrationModal = ({ isOpen, onClose, selectedPlan }) => {
  const [formData, setFormData] = useState({
    user_name: '',
    agency_name: '',
    number_of_account_desired: '',
    email: '',
    heard_of_from: 'Site web',
    phone_number: '',
    registran_role: '',
    agency_or_individual: 'Agence',
    message: `Plan sélectionné: ${selectedPlan}`
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      message: `Plan sélectionné: Plan ${selectedPlan}`
    }));
  }, [selectedPlan]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      await api.post('/waitinglist/', formData);
      setIsSuccess(true);
      setTimeout(() => {
        onClose();
        setIsSuccess(false);
        setFormData({
          user_name: '',
          agency_name: '',
          number_of_account_desired: '',
          email: '',
          heard_of_from: 'Site web',
          phone_number: '',
          registran_role: '',
          agency_or_individual: 'Agence',
          message: ''
        });
      }, 2000);
    } catch (error) {
      console.error("There was an error submitting the form!", error);
      setError('Une erreur est survenue. Veuillez réessayer.');
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-xl p-6 max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        {!isSuccess ? (
          <>
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-bold text-gray-900">Inscription - {selectedPlan}</h2>
              <button 
                onClick={onClose}
                className="text-gray-500 hover:text-gray-700 p-2"
              >
                ✕
              </button>
            </div>

            {error && (
              <div className="mb-4 p-3 bg-red-100 text-red-700 rounded-lg">
                {error}
              </div>
            )}

            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Votre nom
                </label>
                <input
                  type="text"
                  name="user_name"
                  required
                  value={formData.user_name}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-blue-600 focus:ring-blue-500 text-gray-900"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Nom de l'agence
                </label>
                <input
                  type="text"
                  name="agency_name"
                  required
                  value={formData.agency_name}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-blue-600 focus:ring-blue-500 text-gray-900"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Votre Rôle
                </label>
                <input
                  type="text"
                  name="registran_role"
                  required
                  value={formData.registran_role}
                  onChange={handleChange}
                  placeholder="exemple : Gérant agence, Agent immo"
                  className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-blue-600 focus:ring-blue-500 text-gray-900"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Nombre de comptes souhaités
                </label>
                <input
                  type="number"
                  name="number_of_account_desired"
                  required
                  min="1"
                  value={formData.number_of_account_desired}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-blue-600 focus:ring-blue-500 text-gray-900"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  required
                  value={formData.email}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-blue-600 focus:ring-blue-500 text-gray-900"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Numéro de téléphone
                </label>
                <input
                  type="tel"
                  name="phone_number"
                  required
                  value={formData.phone_number}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-blue-600 focus:ring-blue-500 text-gray-900"
                />
              </div>

              <input type="hidden" name="agency_or_individual" value={formData.agency_or_individual} />
              <input type="hidden" name="heard_of_from" value={formData.heard_of_from} />
              <input type="hidden" name="message" value={formData.message} />

              <button
                type="submit"
                disabled={isLoading}
                className={`w-full py-3 px-4 rounded-lg font-medium bg-gradient-to-r from-blue-600 via-purple-500 to-pink-500 text-white transition-all ${
                  isLoading ? 'opacity-70 cursor-not-allowed' : 'hover:opacity-90'
                }`}
              >
                {isLoading ? (
                  <div className="flex items-center justify-center">
                    <svg className="animate-spin h-5 w-5 mr-3 text-white" viewBox="0 0 24 24">
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                        fill="none"
                      />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                    Envoi en cours...
                  </div>
                ) : (
                  'Soumettre'
                )}
              </button>
            </form>
          </>
        ) : (
          <div className="flex flex-col items-center justify-center p-8">
            <div className="relative">
              <svg
                className="w-24 h-24 success-checkmark"
                viewBox="0 0 52 52"
              >
                <circle
                  className="fill-none stroke-green-500 stroke-[4]"
                  cx="26"
                  cy="26"
                  r="24"
                />
                <path
                  className="fill-none stroke-green-500 stroke-[4] origin-center"
                  d="M14.1 27.2l7.1 7.2 16.7-16.8"
                />
              </svg>
            </div>
            <p className="mt-4 text-xl font-semibold text-green-600">
              Inscription réussie!
            </p>
            <p className="mt-2 text-gray-600">
              Nous vous contacterons dans les 48 heures.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

const Pricing = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('');

  const handlePlanSelection = (planName) => {
    setSelectedPlan(planName);
    setIsModalOpen(true);
  };

  const pricing = [
    {
      name: "Starter",
      price: "15 000",
      period: "FCFA/mois",
      transitionOffer: "3 mois à 7 500 FCFA/mois",
      limits: [
        "5 collaborateurs maximum",
        "40 biens immobiliers maximum",
        "Locataires illimités",
        "50 Gb de stockage"
      ],
      features: [
        "Gestion complète des biens et locataires",
        "Encaissement par mobile money",
        "Facturation automatique",
        "Tableau de bord en temps réel",
        "États de paiements automatiques",
        "Gestion de documents",
        "Tableau analytique IA (bientôt)",
        "Support pendant les heures de bureau",
        "Formation initiale incluse"
      ]
    },
    {
      name: "Pro",
      price: "25 000",
      period: "FCFA/mois",
      transitionOffer: "3 mois à 12 500 FCFA/mois",
      popular: true,
      limits: [
        "10 collaborateurs maximum",
        "Biens immobiliers illimités",
        "Locataires illimités",
        "Stockage illimité"
      ],
      features: [
        "Gestion complète des biens et locataires",
        "Encaissement par mobile money",
        "Facturation automatique",
        "Tableau de bord en temps réel",
        "Gestion de documents",
        "États de paiements automatiques",
        "Support prioritaire",
        "Tableau analytique IA avancé (bientôt)",
        "Formation sur site",
        "Accompagnement transition personnalisé"
      ]
    },
    {
      name: "Enterprise",
      price: "Sur mesure",
      period: "",
      transitionOffer: "Plan de transition personnalisé",
      limits: [
        "Collaborateurs illimités",
        "Biens immobiliers illimités",
        "Locataires illimités",
        "Stockage illimité",
        "Accès API",
        "Personnalisation avancée"
      ],
      features: [
        "Gestion complète des biens et locataires",
        "Encaissement par mobile money",
        "Facturation automatique",
        "Tableau de bord en temps réel",
        "Gestion de documents",
        "Support dédié",
        "Accompagnement personnalisé",
        "Facture normalisée",
        "Plan de transition sur mesure"
      ]
    }
  ];

  return (
    <div id="pricing" className="bg-white py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Transition Period Notice */}
        <div className="text-center mb-12 max-w-3xl mx-auto">
          <div className="bg-blue-50 rounded-xl p-6 mb-12">
            <div className="flex items-center justify-center mb-4">
              <Clock className="w-6 h-6 text-blue-600 mr-2" />
              <h3 className="text-xl font-semibold text-gray-900">Période de Transition</h3>
            </div>
            <p className="text-gray-600">
              Pour garantir une transition en douceur, profitez de 3 mois à tarif réduit pour adopter progressivement Recouvro 
              tout en maintenant vos pratiques actuelles.
            </p>
          </div>
        </div>

        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">
            Des Prix Adaptés à votre Taille
          </h2>
          <p className="text-xl text-gray-600">
            Toutes les fonctionnalités incluses dans chaque offre
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          {pricing.map((plan, index) => (
            <div 
              key={index}
              className={`relative bg-white rounded-xl ${
                plan.popular ? 'ring-2 ring-blue-600 shadow-lg' : 'border border-gray-200'
              } p-8`}
            >
              {plan.popular && (
                <div className="absolute top-0 right-6 transform -translate-y-1/2">
                  <div className="bg-gradient-to-r from-blue-600 to-purple-600 text-white px-4 py-1 rounded-full text-sm font-medium">
                    Recommandé
                  </div>
                </div>
              )}
              <h3 className="text-xl font-semibold mb-2">{plan.name}</h3>
              <div className="mb-2">
                <span className="text-4xl font-bold">{plan.price}</span>
                <span className="text-gray-600 ml-2">{plan.period}</span>
              </div>
              <div className="mb-6">
                <span className="inline-flex items-center text-blue-600 bg-blue-50 px-3 py-1 rounded-full text-sm">
                  <Clock className="w-4 h-4 mr-1" />
                  {plan.transitionOffer}
                </span>
              </div>

              <div className="mb-6">
                <h4 className="font-semibold mb-2">Limites</h4>
                <ul className="space-y-2">
                  {plan.limits.map((limit, limitIndex) => (
                    <li key={limitIndex} className="flex items-start">
                      <Check className="w-5 h-5 text-green-500 mr-2 flex-shrink-0" />
                      <span className="text-gray-600">{limit}</span>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="mb-8">
                <h4 className="font-semibold mb-2">Fonctionnalités incluses</h4>
                <ul className="space-y-2">
                  {plan.features.map((feature, featureIndex) => (
                    <li key={featureIndex} className="flex items-start">
                      <Check className="w-5 h-5 text-green-500 mr-2 flex-shrink-0" />
                      <span className="text-gray-600">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>

              <button
                onClick={() => handlePlanSelection(plan.name)}
                className={`w-full py-3 rounded-lg font-medium ${
                  plan.popular
                    ? 'bg-gradient-to-r from-blue-600 via-purple-500 to-pink-500 text-white hover:opacity-90'
                    : 'bg-gradient-to-r from-gray-50 to-gray-100 text-gray-900 hover:opacity-90'
                } transition-all shadow-sm hover:shadow-md`}
              >
                {plan.price === 'Sur mesure' ? 'Contactez-nous' : 'Commencer'}
              </button>
            </div>
          ))}
        </div>

{/* Money Back Guarantee Section */}
<div className="mt-16 max-w-3xl mx-auto">
          <div className="bg-gradient-to-r from-blue-50 to-purple-50 rounded-2xl p-8">
            <div className="bg-white rounded-xl p-8 shadow-md">
              <div className="flex items-center justify-center mb-6">
                <div className="w-16 h-16 bg-gradient-to-r from-blue-600 to-purple-600 rounded-full flex items-center justify-center">
                  <ShieldCheck className="w-8 h-8 text-white" />
                </div>
              </div>
              <h3 className="text-2xl font-bold text-gray-900 text-center mb-4">
                Garantie "Satisfait ou Remboursé"
              </h3>
              <p className="text-gray-600 text-center mb-4">
                Nous comprenons qu'adopter un nouveau système de gestion est un changement important qui nécessite du temps et des ajustements. C'est pourquoi nous vous accompagnons pendant 90 jours dans cette transition.
              </p>
              <p className="text-gray-600 text-center mb-6">
                À l'issue de cette période, si malgré notre accompagnement, vous estimez que Recouvro ne répond pas à vos besoins, nous vous remboursons intégralement. Sans condition, sans question posée.
              </p>
              <div className="flex items-center justify-center space-x-4 text-sm text-gray-500">
                <span className="flex items-center">
                  <Check className="w-4 h-4 text-green-500 mr-2" />
                  Remboursement intégral
                </span>
                <span className="flex items-center">
                  <Check className="w-4 h-4 text-green-500 mr-2" />
                  Sans condition
                </span>
                <span className="flex items-center">
                  <Check className="w-4 h-4 text-green-500 mr-2" />
                  Garantie post-transition
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RegistrationModal 
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        selectedPlan={selectedPlan}
      />

      <style jsx>{`
        @keyframes draw {
          to {
            stroke-dashoffset: 0;
          }
        }

        @keyframes circleFadeIn {
          to {
            stroke-dashoffset: 0;
            opacity: 1;
          }
        }

        .success-checkmark circle {
          stroke-dasharray: 151;
          stroke-dashoffset: 151;
          animation: circleFadeIn 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
        }

        .success-checkmark path {
          stroke-dasharray: 48;
          stroke-dashoffset: 48;
          animation: draw 0.6s 0.4s cubic-bezier(0.65, 0, 0.45, 1) forwards;
        }
      `}</style>
    </div>
  );
};

export default Pricing;