import React, { useState, useEffect } from 'react';
import { PlusCircle, Pencil, Search } from 'lucide-react';
import { Link } from 'react-router-dom';
import api from '../../../api';

const TenantList = () => {
  const [tenants, setTenants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchTenants();
  }, []);

  const fetchTenants = async () => {
    try {
      const response = await api.get('/tenants/');
      if (Array.isArray(response)) {
        setTenants(response);
        console.log('Tenants set to:', response);
      } else {
        console.warn('Unexpected response format:', response);
        setTenants([]);
      }
      setError(null);
    } catch (error) {
      setError('Erreur lors du chargement des agences');
      console.error('Error fetching tenants:', error);
    } finally {
      setLoading(false);
    }
  };

  const getFilteredTenants = () => {
    console.log('Current tenants state:', tenants); // Debug log
    if (!Array.isArray(tenants)) {
      console.warn('Tenants is not an array:', tenants);
      return [];
    }
    
    // First filter out public tenant, then apply search
    const filtered = tenants
      .filter(tenant => tenant?.schema_name !== 'public')
      .filter(tenant =>
        tenant?.schema_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        tenant?.name?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    return filtered;
  };

  if (error) {
    return (
      <div className="p-6 text-center text-red-600">
        <p>{error}</p>
        <button 
          onClick={fetchTenants}
          className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
        >
          Réessayer
        </button>
      </div>
    );
  }

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold">Agences Immobilières</h2>
        <Link 
          to="/admin/tenants/new"
          className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
        >
          <PlusCircle className="w-5 h-5 mr-2" />
          Nouvelle Agence
        </Link>
      </div>

      <div className="mb-4 relative">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <Search className="h-5 w-5 text-gray-400" />
        </div>
        <input
          type="text"
          placeholder="Rechercher une agence..."
          className="pl-10 w-full p-2 border border-gray-300 rounded-md"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {loading ? (
        <div className="flex justify-center items-center py-8">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
        </div>
      ) : (
        <div className="bg-white rounded-lg shadow overflow-hidden">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Nom
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Schema
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Domaine
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Statut
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {getFilteredTenants().map((tenant) => (
                <tr key={tenant.id}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm font-medium text-gray-900">{tenant.name}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-500">{tenant.schema_name}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-500">{tenant.schema_name}.recouvro.com</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      tenant.paid_until && new Date(tenant.paid_until) > new Date()
                        ? 'bg-green-100 text-green-800' 
                        : 'bg-red-100 text-red-800'
                    }`}>
                      {tenant.paid_until && new Date(tenant.paid_until) > new Date() ? 'Actif' : 'Inactif'}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                    <Link
                      to={`/admin/tenants/${tenant.schema_name}`}
                      className="text-blue-600 hover:text-blue-900"
                    >
                      <Pencil className="w-5 h-5" />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {getFilteredTenants().length === 0 && !loading && (
            <div className="text-center py-4 text-gray-500">
              Aucune agence trouvée
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TenantList;