import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../../api';

const UpdateTenant = () => {
  const { schema_name } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [originalData, setOriginalData] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    paid_until: '',
    users_authorized: 1,
    properties_authorized: 1,
    tenant_mail: '',
    country: '',
    tenant_type: '',
    on_trial: false,
    trial_ends: null
  });

  useEffect(() => {
    fetchTenantDetails();
  }, [schema_name]);

  const fetchTenantDetails = async () => {
    try {
      const tenant = await api.get(`/tenants/${schema_name}/`);
      const { schema_name: _, ...tenantData } = tenant; // Exclude schema_name
      setOriginalData(tenantData);
      setFormData({
        name: tenant.name || '',
        paid_until: tenant.paid_until ? tenant.paid_until.split('T')[0] : '',
        users_authorized: tenant.users_authorized || 1,
        properties_authorized: tenant.properties_authorized || 1,
        tenant_mail: tenant.tenant_mail || '',
        country: tenant.country || '',
        tenant_type: tenant.tenant_type || 'agency',
        on_trial: tenant.on_trial || false,
        trial_ends: tenant.trial_ends ? tenant.trial_ends.split('T')[0] : null
      });
      setLoading(false);
    } catch (error) {
      console.error('Error fetching tenant:', error);
      setError('Erreur lors du chargement des détails du tenant');
      setLoading(false);
    }
  };

  const getModifiedFields = () => {
    const changes = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== originalData[key]) {
        changes[key] = formData[key];
      }
    });
    return changes;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    const modifiedFields = getModifiedFields();
    
    if (Object.keys(modifiedFields).length === 0) {
      navigate('/admin/tenants');
      return;
    }

    try {
      await api.patch(`/tenants/${schema_name}/`, modifiedFields);
      navigate('/admin/tenants');
    } catch (error) {
      console.error('Error updating tenant:', error);
      setError(error.response?.data?.detail || 'Erreur lors de la mise à jour du tenant');
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="max-w-2xl mx-auto p-6">
      <h2 className="text-2xl font-bold mb-6">Modifier l'agence</h2>
      
      {error && (
        <div className="mb-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded">
          {error}
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700">Nom de l'agence</label>
          <input
            type="text"
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            value={formData.name}
            onChange={(e) => setFormData({...formData, name: e.target.value})}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Email de l'agence</label>
          <input
            type="email"
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            value={formData.tenant_mail}
            onChange={(e) => setFormData({...formData, tenant_mail: e.target.value})}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Pays</label>
          <input
            type="text"
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            value={formData.country}
            onChange={(e) => setFormData({...formData, country: e.target.value})}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Identifiant unique</label>
          <input
            type="text"
            disabled
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md bg-gray-50"
            value={schema_name}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Payé jusqu'au</label>
          <input
            type="date"
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            value={formData.paid_until}
            onChange={(e) => setFormData({...formData, paid_until: e.target.value})}
          />
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Nombre d'utilisateurs autorisés</label>
            <input
              type="number"
              required
              min="1"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              value={formData.users_authorized}
              onChange={(e) => setFormData({...formData, users_authorized: parseInt(e.target.value)})}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Nombre de propriétés autorisées</label>
            <input
              type="number"
              required
              min="1"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              value={formData.properties_authorized}
              onChange={(e) => setFormData({...formData, properties_authorized: parseInt(e.target.value)})}
            />
          </div>
        </div>

        <div className="flex items-center space-x-4">
          <div className="flex items-center">
            <input
              type="checkbox"
              id="on_trial"
              className="h-4 w-4 text-blue-600 rounded"
              checked={formData.on_trial}
              onChange={(e) => setFormData({...formData, on_trial: e.target.checked})}
            />
            <label htmlFor="on_trial" className="ml-2 block text-sm text-gray-700">
              En période d'essai
            </label>
          </div>
          
          {formData.on_trial && (
            <div>
              <label className="block text-sm font-medium text-gray-700">Fin de la période d'essai</label>
              <input
                type="date"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                value={formData.trial_ends || ''}
                onChange={(e) => setFormData({...formData, trial_ends: e.target.value})}
              />
            </div>
          )}
        </div>

        <div className="flex justify-end space-x-4">
          <button
            type="button"
            onClick={() => navigate('/admin/tenants')}
            className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
          >
            Annuler
          </button>
          <button
            type="submit"
            disabled={loading}
            className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 disabled:opacity-50"
          >
            {loading ? 'Mise à jour...' : 'Mettre à jour'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default UpdateTenant;